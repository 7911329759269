import { Component } from 'vue-property-decorator'
import { Config, Connect } from '@vkontakte/superappkit'

// store
import AuthModule from '@/store/modules/auth'
// types
import { SelfResource } from '@/store/types'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// utils
import { clearRedirectStorage, sendMetrik } from '@/utils/functions'
// import ProfileModule from '@/store/modules/profile'
// import { baseURL } from '@/utils/services/config'

Config.init({
  appId: Number(process.env.VUE_APP_VK_ID),
})

@Component
export default class VKIDMixin extends NotifyMixin {
  // NOTE: В mounted должно быть `if (this.payload) AuthModule.setVKPayload(this.payload)`
  protected payload?: string = this.$route.query.payload as string

  protected get isVKRedirect () {
    return AuthModule.isVKRedirect
  }

  protected get vkPayload () {
    return AuthModule.vkPayload
  }

  protected fetchVKIDData() {
    if (!this.payload && this.$route.name === 'auth.register')
      sendMetrik('show_registration_form')
    if (this.payload) {
      const payload = JSON.parse(this.payload)
      AuthModule.loginVK({
        id: payload.user.id,
        silentToken: payload.token,
        uuid: payload.uuid,
      })
        .then((response: { isNewUser?: boolean, user: SelfResource }) => {
          if (response.isNewUser)
            sendMetrik('registration')
          const redirect = localStorage.getItem('redirect')
          if (redirect) {
            this.$router.push(redirect.toString()).then(clearRedirectStorage)
          } else {
            // Если вход через вк производится со страницы покупки по ссылке, то после успешной авторизации пользователя нужно оставить на этой странице
            if (this.$route.name === 'offer') {
              this.$router.replace(({ query: {} }))
            } else {
              response.user.permissions.length ? this.$router.push({ name: 'manager' }).then(clearRedirectStorage) : this.$router.push({ name: 'master' }).then(clearRedirectStorage)
            }
          }
        })
        .catch(this.notifyError)
        .finally(this.resetVKPayload)

      return
    }
  }

  protected async patchVK () {
    if (this.isVKRedirect) {
      return await AuthModule.patchVK({
        id: this.vkPayload.user.id,
        silentToken: this.vkPayload.token,
        uuid: this.vkPayload.uuid,
      })
        .then(() => {
          AuthModule.getUser()
        })
        .finally(this.resetVKPayload)
    }
  }

  protected handlePatchVK () {
    return Connect.redirectAuth({
      action: undefined,
      screen: undefined,
      source: '',
      state: '',
      url: `${process.env.VUE_APP_BACKEND_ENDPOINT}/r/vk/auth/`,
    })
  }

  protected handleLoginVK(action: 'login' | 'register') {
    if (this.$route.query.redirect) {
      localStorage.setItem('redirect', this.$route.query.redirect.toString())
    }
    return Connect.redirectAuth({
      action: undefined,
      screen: undefined,
      source: '',
      state: '',
      url: `${process.env.VUE_APP_BACKEND_ENDPOINT}/auth/${action}`,
    })
  }

  private handleLoginVKBuyLink(hash: string) {
    return Connect.redirectAuth({
      action: undefined,
      screen: undefined,
      source: '',
      state: '',
      url: `${process.env.VUE_APP_BACKEND_ENDPOINT}/c/${hash}`,
    })
  }

  protected resetVKPayload () {
    AuthModule.resetVKPayload()
  }
}
